.rmsc {


    .dropdown-container {
        background-color: var(--kt-input-solid-bg) !important;
        border-color: var(--kt-input-solid-bg) !important;
        color: var(--kt-input-solid-color) !important;
        transition: color 0.2s ease !important;

        &:focus-within {
            box-shadow: none !important;
        }

        .dropdown-heading-value {
            padding-top: 0.825rem;
            padding-bottom: 0.825rem;
            padding-left: 0.66rem;

            span{
                color: var(--kt-input-solid-color) !important;

            }
        }
    }

}